<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { getBrokerageApi } from '@/api/brokerage'


/**
 * Projects Grid component
 */
export default {
  page: {
    title: "Team",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Team ",
      items: [
        {
          text: "Brokerage",
        },
        {
          text: "Team",
          active: true,
        },
      ],
      teams: [],
    };
  },
  components: {
    Layout,
    PageHeader,
  },

  methods: {
    team_edit_route(team) {

      this.$router.push({ name: 'brokerage-team-edit', query: { team_id: team.id } })
    },

    delete_team(team) {
      getBrokerageApi().officeApi.team_delete({ team_id: team.id }).then((res) => {
        if (res.errCode == 0) {
          this.$router.go()
        } else {
          this.$alertify.error("Remove  Team Failed " + res.errCode);
        }
      })
    },

  },
  created() {
    getBrokerageApi().officeApi.team_list().then((res) => {
      res.data.map((o) => {
        this.teams.push({
          type: o.name,
          title: o.branch_name,
          content: o.address,
          office: o.office,
          leaders: o.leaders,
          members : o.members,
          name: o.name,
          branch_name: o.branch_name,
          address: o.address,
          id: o.id,
          office_id: o.office?.id
        })
      })

    })
  },
  mounted() {


  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="mb-3">
                  <router-link to="/brokerage/team/edit" class="btn btn-light">
                    <i class="uil uil-plus me-1"></i> Add New Team
                  </router-link>
                </div>
              </div>
              <!-- end col -->
              <div class="col-md-9">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">

                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="mt-2">

              <div class="row">
                <div class="col-xl-3 col-sm-6" v-for="(item, index) in teams" :key="index">
                  <div class="card border shadow-none border">
                    <div class="card-body">
                      <div class="d-flex align-items-start mb-3">
                        <div class="flex-grow-1">
                         
                        </div>
                        <b-dropdown variant="white" class="flex-shrink-0" toggle-class="text-body font-size-16 p-0"
                          right>
                          <template v-slot:button-content>
                            <feather type="more-horizontal" class="icon-xs"></feather>
                          </template>
                          <b-dropdown-item @click="team_edit_route(item)">Edit</b-dropdown-item>
                          <b-dropdown-item @click="delete_team(item)">Remove</b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <div>
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <router-link :to="{ name: 'brokerage-team-edit', query: { team_id: item.id } }"
                            class="text-dark">{{ item.title
                            }}</router-link>
                        </h5>
                        <p class="text-muted mb-4 text-truncate">
                          Office : {{ item.office ? item.office.branch_name : '' }}
                        </p>
                       
                      </div>

                    </div>
                    <!-- end card body -->
                  </div>
                  <!-- end card -->
                </div>
              </div>

            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
